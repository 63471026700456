@import "./src/styles/breakpoints";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: visible;
  z-index: var(--login-popup);
}

.popupClose {
  visibility: hidden;
  opacity: 0;

  .body {
    opacity: 0;
  }
}

.overlay {
  background: rgba(255, 255, 255, 0.82);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 700px;
  background-color: var(--login-bg);
  z-index: 2;
  padding: 20px 15px 20px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
  opacity: 1;
  transition: 0.3s;

  @include breakpoint('m') {
    max-height: 800px;
    height: auto;
  }

  @media (max-height: 850px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 15px 10px;
  }
}

.closeBtn {
  position: absolute;
  right: 12px;
  top: 12px;
}

.cloud {
  perspective: 400px;
  height: 270px;
  width: 100%;
  position: absolute;
  bottom: -150px;
  left: 0;
  overflow: hidden;
  z-index: -1;
  background-image: url('../../images/clouds/login-cloud.png');
  background-repeat: no-repeat;
  background-size: 200%;
  /* background-position: center 210%;*/

  @include breakpoint('s') {
    background-size: cover;
  }

  @include breakpoint('m') {
    bottom: 0;
  }

  @include breakpoint('el') {
    bottom: 0;
  }

  @media (max-height: 850px) {
    bottom: 0;
  }
}
