@import "./src/styles/breakpoints";

.user-cabinet__wrapper {

  @include breakpoint('el') {
    min-height: calc(100vh - 86px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    padding-bottom: 25px;
    position: relative;
    z-index: 1;
    background-image: url(./img/cloud_cabinet_bottom.png);
    background-repeat: no-repeat;
    background-position: 70% bottom;
  }
}

.user-cabinet__wrapper:before {
  @include breakpoint('el') {
    content: '';
    display: block;
    width: 100%;
    height: 167px;
    position: absolute;
    left: 0;
    top: -86px;
    z-index: -1;
    background-image: url(./img/cloud_cabinet_top.png);
    background-repeat: no-repeat;
    background-position: 25% top;
    pointer-events: none;
  }
}

.user-cabinet__exit {
  font-size: 30px;
  color: #fff;
  background-color: transparent;
  padding: 0;
  border: none;
  font-family: var(--font-secondary);
  cursor: pointer;
}

.user-cabinet__input-wrapper {
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  font-family: var(--font-third);
  font-size: 24px;
  font-weight: bold;
  color: #4E4E4E;
}

.user-cabinet__share-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #9E9EE8;
  border-radius: 50%;
  border: 2px solid #9E9EE8;
  margin: 0 11px;
  cursor: pointer;
  transition: all .3s ease;
}

.user-cabinet__share-link--viber {
  @include breakpoint('l') {
    display: none;
  }
}

.user-cabinet__share-link:hover,
.user-cabinet__share-link--copied {
  background: transparent;
  border-color: #fff;
}

.user-cabinet__share-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.user-cabinet__controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.user-cabinet__top {
  @include breakpoint('el') {
    background-image: url(./img/birds.png);
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-position: right 15% top 10%;
    align-self: flex-start;
/*    margin-top: 10%;*/
  }
}

.user-cabinet__share {
  margin: 20px 0;
  text-align: center;
}

.user-cabinet__desc {
  padding: 0 12px;
  @include breakpoint('el') {
    background-image: url(./img/cloud_cabinet_right.png);
    background-repeat: no-repeat;
    background-position: right 10%;
  }
}

.user-cabinet__no-result {
  text-align: center;
  font-size: 19px;
  font-weight: 800;
  font-family: var(--font-third);
  text-transform: uppercase;
  margin: 40px auto 30px;
  max-width: 350px;

  @include breakpoint('el') {
    font-size: 30px;
    max-width: 550px;
  }
}

.user-cabinet__desc--no-result {
  @include breakpoint('el') {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.user-cabinet__save-result {
  margin-top: 30px;
}

.user-cabinet__loading {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
