@import "./src/styles/breakpoints";

.charImg {
  width: 100%;
  background-image: url('../../../images/clouds/big-cloud_2.png');
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100% 100%;

  img {
    max-width: 400px;
    max-height: 400px;
    width: 100%;
    object-fit: contain;

    @include breakpoint('el') {
      max-width: 570px;
      max-height: 570px;
    }

    @media (max-height: 900px) {
      max-width: 40vh;
      max-height: 370px;
    }
  }
}

.person-item-page {
  .charImg {
    img {
      @media (max-height: 900px) {
        max-width: 390px;
        max-height: 390px;
      }
    }
  }

  .user-cabinet__share-wrapper {
    @media (max-height: 900px) {
      margin-top: 15px;
    }
  }
}


.charImg--no-bg {
  margin-bottom: 20px;
  @include breakpoint('s') {
    background-image: none;
  }

  img {
    @include breakpoint('el') {
      max-width: 800px;
      max-height: 800px;
      width: 100%;
    }
  }

}
