@import "./src/styles/breakpoints";

.package {

  --bottom: 0px;
  --left: 0px;
  --size: 0px;
  position: absolute;
  width: 100%;
  max-width: 375px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 76vh;
  height: calc(var(--vh, 1vh) * 76);
  top: 50%;
  max-height: 430px;

  @media (min-width: 375px) {
    --bottom: -15px;
    transform: translateX(-50%) translateY(-50%) scale(1.1);
  }

  @include breakpoint('xs') {
    --bottom: -20px;
    transform: translateX(-50%) translateY(-50%) scale(1.1);
  }

  @include breakpoint('s') {
    transform: translateX(-50%) translateY(-50%) scale(1.4);
  }

  @include breakpoint('m') {
    transform: translateX(-50%) translateY(-50%) scale(1.5);
  }

  @media (min-width: 800px) {
    transform: translateX(-50%) translateY(-50%) scale(2.4);
  }

  @include breakpoint('el') {
    --bottom: 40px;
    max-height: 510px;
    transform: translateX(-50%) translateY(-50%) scale(1.4);
  }

  @include breakpoint('xxl') {
    --bottom: 45px;
    max-height: 510px;
    transform: translateX(-50%) translateY(-50%) scale(2);
  }
}

.heroImage {
  width: 700px;
  position: absolute;
  left: 35%;
  top: 400px;
}

.package__cloud {
  position: absolute;
  max-width: 800px;
  width: 200%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @include breakpoint('s') {
    width: 300%;
    bottom: -80px;
  }

  @include breakpoint('m') {
    max-width: 1400px;
    bottom: -180px;
  }

  @include breakpoint('el') {
    bottom: -30px;
    left: 50%;
    width: 200%;
    max-width: initial;
  }

}


.package__item {
  position: absolute;
  width: calc(128px + var(--size));
  left: 33%;
  bottom: calc(100px + var(--bottom));

  /*  @include breakpoint('el') {
      bottom: 200px;
      left: 40%;
    }*/
}

.package__back {
  position: absolute;
  width: calc(128px + var(--size));
  left: 32%;
  bottom: calc(212px + var(--bottom));
  z-index: -1;
}

.package__ear {
  position: absolute;
  width: calc(30px + var(--size));
  left: 58%;
  bottom: calc(158px + var(--bottom));
}

.package__man {
  position: absolute;
  width: calc(96px + var(--size));
  bottom: calc(231px + var(--bottom));
  left: 40%;
}

.package__ladder {
  position: absolute;
  width: calc(150px + var(--size));
  bottom: calc(38px + var(--bottom));
  left: 53%
}

.package__fairy {
  position: absolute;
  bottom: calc(227px + var(--bottom));
  width: calc(39px + var(--size));
  left: 38%;
}


.package__pepper {
  position: absolute;
  bottom: calc(215px + var(--bottom));
  width: calc(30px + var(--size));
  left: 46%;
}

.package__cow {
  position: absolute;
  width: calc(54px + var(--size));
  bottom: calc(120px + var(--bottom));
  left: -4%;

  @media (min-width: 375px) {
    left: 2%;
  }
}

.package__catman {
  position: absolute;
  width: calc(50px + var(--size));
  bottom: calc(104px + var(--bottom));
  left: 15%;
}

.package__water {
  position: absolute;
  width: calc(50px + var(--size));
  bottom: calc(145px + var(--bottom));
  left: 80%;

  @include breakpoint('m') {
    bottom: calc(141px + var(--bottom));
    left: 85%;
    transform: scale(1.4);
  }
}

.package__paint {
  position: absolute;
  width: calc(37px + var(--size));
  bottom: calc(212px + var(--bottom));
  left: 59%;
  // width: calc(50px + var(--size));
  // bottom: calc(175px + var(--bottom));
  // left: 63%;
}

.package__watermelon {
  position: absolute;
  width: calc(42px + var(--size));
  bottom: calc(129px + var(--bottom));
  left: 26%;
}

.package__bacon {
  width: calc(110px + var(--size));
  left: 4%;
  position: absolute;
  bottom: calc(144px + var(--bottom));
}

.package__pea {
  position: absolute;
  bottom: calc(103px + var(--bottom));
  left: 86%;
  width: calc(10px + var(--size));
}

.package__cabbage {
  position: absolute;
  bottom: calc(87px + var(--bottom));
  left: 92%;
  width: calc(11px + var(--size));
}

.package__pink-fruit {
  position: absolute;
  width: calc(30px + var(--size));
  bottom: calc(110px + var(--bottom));
  left: 30%;
}

.package__grass {
  position: absolute;
  width: 100px;
  bottom: calc(192px + var(--bottom));
  left: 46%;

  @include breakpoint('m') {
    width: 77px;
  }
}

.package__grass-1 {
  position: absolute;
  width: calc(30px + var(--size));
  bottom: calc(232px + var(--bottom));
  left: 36%;
}

.package__grass-2 {
  position: absolute;
  width: calc(30px + var(--size));
  bottom: calc(232px + var(--bottom));
  left: 42%;
}

.package__noodles {
  position: absolute;
  bottom: calc(162px + var(--bottom));
  left: 27%;
  width: 120px;
}


/* animations */

.main-hero__row {
  animation: background-anim 1.5s ease;
}

.labels-char img:first-child {
  animation: label-first-mob 1.6s cubic-bezier(0.4, 0.0, 0.2, 1);
  @include breakpoint('el') {
    animation: label-first 1.6s cubic-bezier(0.4, 0.0, 0.2, 1);
  }
}

.labels-char img:last-child {
  animation: label-last-mob 1.6s cubic-bezier(0.4, 0.0, 0.2, 1);
  @include breakpoint('el') {
    animation: label-last 1.6s cubic-bezier(0.4, 0.0, 0.2, 1);
  }
}

/*.skipping-man, .package__grass, .package__grass-2, .package__grass-1, .package__man, .package__fairy, .package__pepper, .package__bacon, .package__catman, .package__watermelon, .package__cow, .package__noodles, .package__ladder, .package__pea, .package__cabbage, .package__pink-fruit, .package__water, .package__paint {
  animation: package-anim 1.3s cubic-bezier(0.4, 0.0, 0.2, 1);
}*/

.package__catman, .package__cow, .package__pea, .package__cabbage, .package__water, .package__paint {
  animation: package-anim 1.3s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.package__bacon {
  animation: package-bacon-anim 1.3s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.package__ladder {
  animation: package-ladder-anim 1s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.package__watermelon {
  animation: package-watermelon-anim 1.1s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.package__pink-fruit {
  animation: package-pink-fruit-anim 1.2s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.skipping-man, .package__noodles {
  transform-origin: center top;
  animation: package-noodles-anim 1.6s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.package__grass {
  animation: package-grass-anim 1.3s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.package__grass-2 {
  animation: package-grass2-anim 1.5s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.package__grass-1 {
  animation: package-grass1-anim 1.6s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.package__man {
  animation: package-man-anim 1.7s cubic-bezier(0.16, 1, 0.3, 1);
}

.package__fairy {
  animation: package-fairy-anim 1.8s cubic-bezier(0.16, 1, 0.3, 1);
}

.package__pepper {
  animation: package-pepper-anim 1.9s cubic-bezier(0.16, 1, 0.3, 1);
}

.package__item, .package__back, .package__ear, .cloud-woman, .lighter-man, .sun-char, .captain-arrow, .hero-info__button, .site-header {
  animation: opacity-anim 1.3s ease;
}

.package__cloud {
  transform-origin: left 60%;
  animation: package-cloud-anim 1.5s cubic-bezier(0.4, 0.0, 0.2, 1);
}

@keyframes background-anim {
  0% {
    background: #fff;
  }
  30% {
    background: #fff;
  }
  100% {
    background: transparent;
  }
}

@keyframes package-anim {
  0% {
    opacity: 0;
    transform: scale(0.7) translateY(30px);
  }
  80% {
    opacity: 0;
    transform: scale(0.7) translateY(30px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes opacity-anim {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes labels-anim {
  0% {
    transform: scale(1.5);
    opacity: 0;
  }
  10% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes label-first {
  0% {
    transform: scale(2) translateX(15%);
    opacity: 0;
  }
  30% {
    transform: scale(2) translateX(15%);
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes label-last {
  0% {
    transform: scale(2) translateX(-15%);
    opacity: 0;
  }
  30% {
    transform: scale(2) translateX(-15%);
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes label-first-mob {
  0% {
    transform: scale(1.35) translate(-3%, 20px);
    opacity: 0;
  }
  30% {
    transform: scale(1.35) translate(-3%, 20px);
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes label-last-mob {
  0% {
    transform: scale(1.35) translate(3%, 20px);
    opacity: 0;
  }
  30% {
    transform: scale(1.35) translate(3%, 20px);
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}


@keyframes package-grass-anim {
  0% {
    opacity: 0;
    transform: scale(0.7) translate(-30%,100%);
  }
  79% {
    opacity: 0;
    transform: scale(0.7) translate(-30%,100%);
  }
  80% {
    opacity: 1;
    transform: scale(0.7) translate(-30%,100%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes package-grass2-anim {
  0% {
    opacity: 0;
    transform: scale(0.7) translate(20%,100%);
  }
  65% {
    opacity: 0;
    transform: scale(0.7) translate(20%,100%);
  }
  75% {
    opacity: 1;
    transform: scale(0.7) translate(20%,100%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes package-grass1-anim {
  0% {
    opacity: 0;
    transform: scale(0.7) translate(100%, 100%);
  }
  80% {
    opacity: 0;
    transform: scale(0.7) translate(100%, 100%);
  }
  90% {
    opacity: 1;
    transform: scale(0.7) translate(100%, 100%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes package-man-anim {
  0% {
    opacity: 0;
    transform: scale(0.7) translateY(100%);
  }
  70% {
    opacity: 0;
    transform: scale(0.7) translateY(100%);
  }
  80% {
    opacity: 1;
    transform: scale(0.7) translateY(100%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes package-fairy-anim {
  0% {
    opacity: 0;
    transform: scale(0.7) translate(10%,100%);
  }
  70% {
    opacity: 0;
    transform: scale(0.7) translate(10%,100%);
  }
  80% {
    opacity: 1;
    transform: scale(0.7) translate(10%,100%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes package-pepper-anim {
  0% {
    opacity: 0;
    transform: scale(0.7) translateY(100%);
  }
  70% {
    opacity: 0;
    transform: scale(0.7) translateY(100%);
  }
  80% {
    opacity: 1;
    transform: scale(0.7) translateY(100%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes package-cloud-anim {
  0% {
    opacity: 0;
    transform: scale(0.001) translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%);
  }
}

@keyframes package-noodles-anim {
  0% {
    opacity: 0;
    transform: scale(0.1) translateY(30%);
  }
  80% {
    opacity: 0;
    transform: scale(0.1) translateY(30%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}


@keyframes package-bacon-anim {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  80% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes package-ladder-anim {
  0% {
    opacity: 0;
    transform: translate(-40%,-40%) scale(0.3);
  }
  80% {
    opacity: 0;
    transform: translate(-40%,-40%) scale(0.3);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes package-watermelon-anim {
  0% {
    opacity: 0;
    transform: translate(100%,0);
  }
  80% {
    opacity: 0;
    transform: translate(100%,0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes package-pink-fruit-anim {
  0% {
    opacity: 0;
    transform: translate(10px,10px) scale(0.001);
  }
  80% {
    opacity: 0;
    transform: translate(10px,10px) scale(0.001);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}