$breakpoints: (
  'xs': 414px,
  's':  576px,
  'm': 768px,
  'l':  992px,
  'el': 1200px,
  'xxl': 1600px
) !default;

@mixin breakpoint($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

