.skipping-man {
  position: absolute;
  left: 51%;
  bottom: calc(85px + var(--bottom));
  z-index: 10;
  width: calc(80px + var(--size));
}

// .skipping-man__img {
//   position: relative;
//   animation: skipp 0.7s infinite cubic-bezier(0, 2.05, 0.34, 1.53);
// }

.skipping-man__img {
  position: relative;
  animation: skipp-anim 3.8s infinite cubic-bezier(0, 2.05, 0.34, 1.53);
}


@keyframes skipp-anim {
  0% {
    top: 0;
  }
  20% {
    top: 0;
  }
  30% {
    top: -5px;
  }
  40% {
    top: 0;
  }
  50% {
    top: -5px;
  }
  60% {
    top: 0;
  }
  70% {
    top: -5px;
  }
  80% {
    top: 0;
  }
  100% {
    top: 0;
  }
}

@keyframes skipp {
  0% {

    top: 5px;
  }
  50% {

    top: 15px;
  }
  100% {

    top: 5px;
  }
}
