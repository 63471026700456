@import "./src/styles/breakpoints";

.row {


  /*  @include breakpoint('l') {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }*/
}

.list {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.item {
  margin-bottom: 20px;

  @include breakpoint('el') {
    margin-bottom: 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  text-decoration: none;
  font-size: 26px;
  font-family: var(--font-third);
  line-height: 27px;
  font-weight: 700;
  color: var(--contrast-color);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 10px;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;

  @include breakpoint('s') {
    font-size: 32px;
  }

  @include breakpoint('m') {
    font-size: 36px;
  }

  @include breakpoint('l') {
    font-size: 40px;
     -webkit-text-stroke-width: 0.7px;
  }
}


.itemPink {
  color: #FF89D3;
}

.itemGreen {
  color: #B1CA1D;
}

.itemOrange {
  color: #FE932B;
}
