.login-cloud {
 /* display: none;*/
  .cloudBase {
    position: absolute;
    /*    left: 356px;
        top: 150px;*/

    top: 250px;

    width: 40px;
    height: 20px;
    margin-left: -10px;
    margin-top: -10px;
  }

  .cloudLayer {
    width: 256px;
    max-width: initial;
    height: 256px;
    position: absolute;
    left: 50%;
    top: 50%;

    margin-left: -128px;
    margin-top: -128px;
    transition: opacity 0.5s ease-out;
  }
}


