@import "./src/styles/breakpoints";

.main-hero {
  width: 100%;
  position: relative;
  /* user-select: none;
   pointer-events: none;
 */
  .wrapper {
    position: relative;
    z-index: 2;
  }
}

.main-hero__wrapper {

  padding-right: 0 !important;
  padding-left: 0 !important;

}

.main-hero__row {
  padding-top: var(--header-height);
  position: relative;
  min-height: calc(100vh + 400px);
  min-height: calc(var(--vh, 1vh) * 100 + 500px);

  @include breakpoint('el') {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }

  @media (max-height: 500px) {
    min-height: calc(120vh + 400px);
    min-height: calc(var(--vh, 1vh) * 120 + 500px);
  }
}

.main-hero__cloud {
  position: absolute;
  top: 180px;
  left: 0;
  height: 100%;
  width: 100%;
  /*  overflow-x: hidden;
    overflow-y: initial;*/


  .cloudBase {
    position: absolute;
    /*    left: 356px;
        top: 150px;*/
    top: 50px;
    width: 40px;
    height: 20px;
    margin-left: -10px;
    margin-top: -10px;
  }

  .cloudLayer {
    width: 1256px;
    max-width: initial;
    height: 1256px;
    position: absolute;
    left: 50%;
    top: 50%;
    filter: brightness(0) invert(1);
    margin-left: -128px;
    margin-top: -128px;
    transition: opacity 0.5s ease-out;
  }
}

.main-hero__package-wrapper {
  top: 0;
  position: absolute;
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.main-hero__question2 {
  display: none;

  @include breakpoint('el') {
    width: 190px;
    height: 160px;
    display: initial;
    position: absolute;
    top: 16%;
    left: 4%;
  }
}

.main-hero__question3 {
  display: none;
  @include breakpoint('el') {
    width: 236px;
    height: 160px;
    position: absolute;
    top: 9%;
    left: 67%;
    display: initial;
  }

  img:nth-child(1) {
    right: 217px;
  }

  img:nth-child(2) {
    top: 127px;
    right: 194px;
  }
}

.main-hero__question2-text,
.main-hero__question3-text {

  font-size: 16px;
  font-family: var(--font-third);
  max-width: 100px;
  position: absolute;
  text-align: center;
  font-weight: 700;
  z-index: 10;
  opacity: 0;
}

.main-hero__question2-text {
  right: 0;
  top: 67px;
}


.main-hero__question3-text {
  top: 70px;
  right: 70px;
}

