@import "./src/styles/breakpoints";

.share-mobile-btn {
  padding: 0 12px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  font-family: var(--font-third);
  font-size: 24px;
  font-weight: 700;
  color: #4e4e4e;
  @include breakpoint('el') {
    display: none;
  }
}

.share-desktop-btn {
  display: none;
  @include breakpoint('el') {
    display: flex;
  }
}
