.primaryButton {
  background-image: url(../../images/backgrounds/button-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: transparent;
  border: none;
  font-family: var(--font-secondary);
  color: var(--contrast-color);
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  height: 62px;
  text-align: center;
  width: 100%;
  max-width: 272px;
  padding: 10px;
  cursor: pointer;
  outline: none;
  display: inline-block;
}

.primaryButton--form {
  background-image: none;
  background-color: #0570FF;
  height: 50px;
  padding: 5px;
  max-width: 465px;
}
