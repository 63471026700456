@import "./src/styles/breakpoints";

.hero-info__button {
  position: absolute;
  top: 82vh;
  top: calc(var(--vh, 1vh) * 82);
  width: 90%;
  left: 50%;
  max-width: 275px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  text-decoration: none;
  pointer-events: auto;

  @include breakpoint('s') {
    top: 82vh;
    top: calc(var(--vh, 1vh) * 82);
  }

  @include breakpoint('m') {
    top: 91vh;
    top: calc(var(--vh, 1vh) * 91);
  }

  @media (min-width: 800px) {
    transform: translateX(-50%) scale(1.6);
  }

  @include breakpoint('el') {
    top: 91vh;
    top: calc(var(--vh, 1vh) * 79);
    transform: translateX(-50%);
  }
}
