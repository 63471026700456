@import "./src/styles/breakpoints";

.site-header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: var(--header-index);
  left: 0;
}

.site-header__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0;

  @include breakpoint('l') {
    padding: 25px 0 15px;
  }

  @include breakpoint('el') {
    padding: 25px 35px 15px;
  }
}

.site-header__toggle-wrapper {
  width: 44px;
  height: 46px;
}

.site-header__navigation {
  width: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  transition: 0.7s cubic-bezier(0.4, 0.0, 0.2, 1);

  @include breakpoint('l') {
    max-width: 80vw;
    padding-right: 100px;
  }

  @include breakpoint('el') {
    max-width: 58vw;
    padding-right: 100px;
  }
}

.site-header__logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  width: 240px;

  @include breakpoint('m') {
    top: 20px;
    width: 340px;
  }
}

.site-header__nav-wrapper {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 80px 20px 40px;
  background-color: var(--contrast-color);
}

.site-header__nav-img {
  margin: 0 auto;
  width: 150px;

  @include breakpoint('s') {
    width: 240px;
  }
}

.site-header__guest-desc {
  font-size: 26px;
  font-family: var(--font-third);
  line-height: 27px;
  font-weight: 700;
  color: #9E9EE8;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
  text-align: center;
  margin: 0;

  @include breakpoint('s') {
    font-size: 32px;
  }

  @include breakpoint('m') {
    font-size: 36px;
  }

  @include breakpoint('l') {
    font-size: 40px;
    -webkit-text-stroke-width: 0.7px;
  }
}

.site-header__decorative-bg {
  @include breakpoint('l') {
    background-image: url(./img/nav-decorative.svg);
    position: absolute;
    right: 0;
    top: 0;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 110px;
  }

}

.site-header__navigation--open {
  left: 0;
}

.site-header__license {
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  margin: 0 auto;
  width: 100%;

  a {
    color: inherit;

  }
}
