@import "./src/styles/breakpoints";

.labels-char {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: calc(350px + var(--bottom));
  width: 100%;

  @include breakpoint('s') {
    justify-content: space-between;
    bottom: calc(300px + var(--bottom));
  }

  @include breakpoint('el') {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 34%;
    height: 70px
  }

  img {
    width: 45%;
    max-width: 250px;
    @include breakpoint('el') {
      width: initial;
    }
  }

  .labels-char__text-1 {
    @include breakpoint('el') {
      object-fit: contain;
      width: 132px;
      height: 70px;
    }
  }

  .labels-char__text-2 {
    @include breakpoint('el') {
      object-fit: contain;
      width: 98px;
      height: 70px;
    }
  }


  .hero-silpo-cloud {
    position: absolute;
    left: -8%;
    top: 0;
    width: 67%;
    z-index: -1;
    @include breakpoint('s') {
      left: -12%;
    }
    @include breakpoint('m') {
      left: -13%;
      top: 0;
      width: 67%;
    }
    @include breakpoint('l') {
      left: -13%;
      top: 1%;
      width: 67%;
    }
    @include breakpoint('el') {
      left: -9%;
      top: -10%;
      width: 48%;
    }
  }
}
