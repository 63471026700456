.button {
  position: relative;
  background-image: url(../../images/backgrounds/close-modal-bg.svg);
  background-color: transparent;
  background-size: contain;
  background-position: center;
  border: none;
  width: 33px;
  padding: 0;
  height: 32px;
  cursor: pointer;
  font-size: 20px;
  color: var(--contrast-color);
  font-family: var(--font-primary);

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
  }
}
