.user-header-icon {
  position: relative;
  z-index: 5;
  width: 43px;
  height: 43px;
  background-image: url(./images/user-icon.svg);
  background-color:transparent;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  background-size: contain;
  padding: 0;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
  }
}
