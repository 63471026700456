.row {
  display: grid;
  grid-gap: 20px;

  @media (max-height: 850px) {
    grid-gap: 5px;
  }

  .title {
    margin-bottom: 0;
  }
}

.description {
  margin: 0 0 15px;
  text-align: center;
  max-width: 465px;
  font-size: 20px;
}

.forgetText {
  color: var(--primary-button-color);
  text-align: center;
  margin-bottom: 25px;
  text-transform: uppercase;

  @media (max-height: 850px) {
    grid-gap: 5px;
  }
}

.bottomWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-third);
  font-size: 14px;
  margin-bottom: 10px;


  @media (max-height: 850px) {
    margin-bottom: 0;
  }
}

.forgetPasswordBtn {
  text-decoration: underline;
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: var(--primary-button-color);
  font-family: var(--font-third);
  text-transform: uppercase;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
