@import "./src/styles/breakpoints";

.not-found {
  background-image: url('./img/404/error_s.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;

  @include breakpoint('el') {
    background-image: url('./img/404/error_l .jpg');
  }


  @include breakpoint('el') {
    background-image: url('./img/404/error_xl.jpg');
  }
}

.not-found__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 200px);
  justify-content: center;

  a {
    width: 100%;
    text-align: center;
    max-width: 300px;
  }

  img {
    width: 80%;
    margin: 0 auto;

    @include breakpoint('el') {
      width: 100%;
    }
  }

  p {
    font-size: 24px;
    font-family: var(--font-third);
    font-weight: 800;
    text-transform: uppercase;
    padding: 20px 40px;

    @include breakpoint('el') {
      font-size: 30px;
    }
  }
}
