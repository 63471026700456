@import "./src/styles/breakpoints";

.question-img1 {
  display: none;
  width: 19.4%;
  position: absolute;
  left: 0;
  top: 5%;
  z-index: -1;
  @include breakpoint('l') {
    display: block;
  }
}

.question-img2 {
  display: none;
  width: 20.5%;
  position: absolute;
  right: 3%;
  top: 34%;
  z-index: -1;
  @include breakpoint('m') {
    display: block;
  }
  @include breakpoint('el') {
    top: 30%;
  }
}

.question-img3 {
  display: none;
  width: 26%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  @include breakpoint('m') {
    display: block;
  }
}

.question-img4 {
  display: none;
  width: 17%;
  position: absolute;
  right: 2%;
  bottom: 2%;
  z-index: -1;
  @include breakpoint('m') {
    display: block;
  }
}

.question-img5 {
  display: none;
  width: 3.5%;
  position: absolute;
  left: 19%;
  top: 40%;
  z-index: -1;
  @include breakpoint('m') {
    display: block;
  }
}

.question-img6 {
  display: none;
  width: 2.8%;
  position: absolute;
  right: 18%;
  bottom: 23%;
  z-index: -1;
  @include breakpoint('m') {
    display: block;
  }

}
