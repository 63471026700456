.overlay {
  opacity: 0;
  position: fixed;
  visibility: hidden;
  top: 0;
  transition: 0.2s ease-in-out;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: var(--overlay-index);
  background: rgba(61, 61, 61, 0.44);
}

.overlay--show {
  visibility: visible;
  opacity: 1;
}
