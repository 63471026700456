@import "./src/styles/breakpoints";

.sun-char {
  position: absolute;
  right: 0;
  width: 300px;
  top: 200px;
  height: 350px;
  display: none;

  @include breakpoint('el') {
    display: initial;
  }
}

.sun-char__sun {
  position: absolute;
  right: 20px;
  top: 0;
  transform-origin: center;
  animation: rotateSun 30s infinite;
  animation-delay: 4s;
}

.sun-char__cloud {
  position: absolute;
  bottom: 0;
  right: 0;
}

@keyframes rotateSun {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
