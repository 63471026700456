@import "./src/styles/breakpoints";

.methodology {
  position: relative;
  overflow: hidden;
}

.methodology__body {
  max-width: 1000px;
  background-image: linear-gradient(180deg, #83C9F0 0%, #AEDFFB 100%);
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding: 70px 13px 15px;


  @include breakpoint('l') {
    height: auto;
    padding: 16px 20px 75px;
  }
}

.methodology__body-wrapper {
  @include breakpoint('l') {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.methodology__test {
  display: block;
  width: 100%;
  text-align: center;
  margin: 15px 0;
  text-decoration: none;

  .primaryButton {
    display: block;
    margin: 0 auto;
  }
}

.methodology__cloud {
  bottom: 0;
}

.methodology__body-top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoint('s') {
    padding: 0 60px;
  }

  @include breakpoint('l') {
    flex-direction: column;
    padding: 0;
    min-width: 190px;
    justify-content: center;
  }

  img:nth-child(1) {
    width: 127px;
    position: relative;
    top: 10px;
    height: 58px;

    @include breakpoint('l') {
      margin-bottom: 30px;
      width: 175px;
      height: 76px;
    }
  }

  img:nth-child(2) {
    width: 113px;
    height: 52px;
    @include breakpoint('l') {
      width: 170px;
      height: 76px;
    }
  }
}

.methodology__close {
  @include breakpoint('el') {
    /*  display: none;*/
  }
}

.methodology__body-content {
  font-family: var(--font-four);
  font-size: 20px;
  font-weight: 300;
  color: var(--text-color-2);
  padding: 15px 0 5px;

  @media (min-width: 360px) {
    padding: 35px 0 5px;
  }
  @include breakpoint('s') {
    padding: 50px 60px;
  }


  p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 16px;

    @include breakpoint('l') {
      font-size: 22px;
      max-width: 600px;
    }

    @include breakpoint('el') {
      font-size: 22px;
    }

    @media (min-height: 700px) {
      font-size: 16px;
    }

    @media (min-height: 900px) {
      font-size: 22px;
    }
  }
}

.methodology__body-title {
  font-family: var(--font-third);
  font-size: 18px;
  font-weight: bold;
  color: #4E4E4E;
  margin-top: 0;
  margin-bottom: 16px;

  @media (min-width: 360px) {
    font-size: 20px;
  }

  @include breakpoint('s') {
    font-size: 22px;
  }

  @include breakpoint('l') {
    margin-bottom: 26px;
  }
}

.license__body {
  padding: 20px;
  margin: 0 auto;

  .methodology__body-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      align-self: flex-start;
    }
  }
}

