:root {
  /* font-family */
  --font-primary: 'Nunito', sans-serif;
  --font-secondary: 'Amatic SC', cursive;
  --font-third: 'Balsamiq Sans', cursive;
  --font-four: 'Oswald', 'sans-serif';

  --text-color: #333333;
  --text-color-2: #4F4F4F;
  --contrast-color: #fff;
  --placeholder-color: #828282;
  --primary-button-color: #0570FF;
  --error-color: #DE3224;
  --success-color: #1877F2;;

  --login-bg: #aad7f1;

  --header-height: 61px;
}
