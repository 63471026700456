*,
*::before,
*::after {
  box-sizing: inherit;
}


html {
  box-sizing: border-box;
}

.content-box-component {
  box-sizing: content-box;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 0;
  margin: 0;
  font-family: var(--font-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: var(--text-color);
  background-color: lightblue;

}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.overflowRoot {
  overflow: hidden;
}
