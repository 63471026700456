@import "./src/styles/breakpoints";


.captain-arrow {
  display: none;
  position: absolute;
  right: 19%;
  bottom: -390px;
  transform: scale(0.7);
  z-index: -1;

  @include breakpoint('el') {
    display: initial;
    right: -20%;
    bottom: 180px;
    transform: scale(1);
  }

  @include breakpoint('xxl') {
    right: -30%;
    bottom: 300px;
  }
}

// .captain-arrow__img {
//   position: relative;
//   animation: float 5s infinite cubic-bezier(0, 1.8, 1, -1.39);
// }

.captain-arrow__img {
  position: relative;
  // animation: float-captain 4s infinite linear;
  right: 0;
  top: 0;
  animation: float-captain-new 5s linear reverse;
}

@keyframes float-captain-new {
  0% {
    right: 0;
    top: 0;
    opacity: 1;
  }
  35% {
    right: 20%;
    top: 10px;
    opacity: 1;
  }
  50% {
    right: 40%;
    top: 0;
    opacity: 1;
  }
  65% {
    right: 60%;
    top: 20px;
    opacity: 0;
  }
  100% {
    right: -40%;
    top: 20px;
    opacity: 0;
  }
}

@keyframes float-captain {
  0% {
    right: -40%;
    top: 20px;
    opacity: 0;
  }
  20% {
    right: 0;
    top: 10px;
    opacity: 1;
  }
  40% {
    right: 40%;
    top: 0;
    opacity: 1;
  }
  50% {
    right: 60%;
    top: 20px;
    opacity: 0;
  }
  100% {
    right: -40%;
    top: 20px;
    opacity: 0;
  }
}


@keyframes float {
  0% {
    right: 5px;
    top: 5px;
  }
  50% {
    right: 15px;
    top: 15px;
  }
  100% {
    right: 5px;
    top: 5px;
  }
}
