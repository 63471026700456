.form {
  max-width: 465px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.inputWrapper {
  display: grid;
  grid-gap: 25px;
  margin-bottom: 25px;


  @media (max-height: 850px) {
    grid-gap: 10px;
  }
}
