@import "./src/styles/breakpoints";

.toggle-menu {
  --line-color: var(--contrast-color);
  --line-space: 5px;
  --toggle-width: 32px;
  --toggle-speed: 0.5s;
  /*style after animation*/
  --togle-bias: 8px;
  position: relative;
  display: block;
  width: 46px;
  height: 44px;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  background-image: url(../../images/backgrounds/toggle-close.svg);
  border: none;
  transition: all 0.3s;
  background-repeat: no-repeat;
  background-position: center;
/*  background-size: contain;*/
  z-index: 5;
  outline: none;

  &::before {
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    content: '';
    border-radius: 50%;
    transition: 0.3s ease-out;
  }
}

.toggle-menu:focus-visible {
  outline: initial;
}

.toggle-menu.active {
  /*transition: all 0.3s;*/
  background-image: url(../../images/backgrounds/close-bg.svg);

  &::before {
    background-color: var(--border-light);
  }
}


.toggle-menu__line {
  position: relative;
  display: block;
  width: var(--toggle-width);
  height: 3px;
  background: var(--line-color);
  transition: all 0.3s;
  border-radius: 3px;
  margin: 0 auto;
}

.toggle-menu__wrapper {
  display: block;
  margin: 0 auto;
}

.toggle-menu__line + .toggle-menu__line {
  margin-top: var(--line-space);
}

.active .toggle-menu__line--1 {
  animation: ease var(--toggle-speed) top forwards;
}

.not-active .toggle-menu__line--1 {
  animation: ease var(--toggle-speed) top-2 forwards;
}

.active .toggle-menu__line--2 {
  animation: ease var(--toggle-speed) scaled forwards;
}

.not-active .toggle-menu__line--2 {
  animation: ease var(--toggle-speed) scaled-2 forwards;
}

.active .toggle-menu__line--3 {
  width: var(--toggle-width);
  animation: ease var(--toggle-speed) bottom forwards;
}

.not-active .toggle-menu__line--3 {
  animation: ease var(--toggle-speed) bottom-2 forwards;
}

@keyframes top {

  0% {
    top: 0;
    transform: rotate(0);
  }

  50% {
    top: var(--togle-bias);
    transform: rotate(0);
  }

  100% {
    top: var(--togle-bias);
    transform: rotate(45deg);
  }
}

@keyframes top-2 {

  0% {
    top: var(--togle-bias);
    transform: rotate(45deg);
  }

  50% {
    top: var(--togle-bias);
    transform: rotate(0deg);
  }

  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {

  0% {
    bottom: 0;
    transform: rotate(0);
  }

  50% {
    bottom: var(--togle-bias);
    transform: rotate(0);
  }

  100% {
    bottom: var(--togle-bias);
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {

  0% {
    bottom: var(--togle-bias);
    transform: rotate(135deg);
  }

  50% {
    bottom: var(--togle-bias);
    transform: rotate(0);
  }

  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {

  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
