@import "./src/styles/breakpoints";

.character-desc {
  font-size: 20px;
  line-height: 1.5;
  font-family: var(--font-four);
  font-weight: 300;
  color: var(--text-color-2);
  padding: 70px 0 43px;
  position: relative;
  @include breakpoint('el') {
    padding: 20px 35px 40px;
  }
}

#character-desc__inner {
  @include breakpoint('el') {
    height: calc(100vh - 86px - 86px);
    overflow: auto;
    scrollbar-width: none;
  }
}

.character-desc__inner {
  @include breakpoint('el') {
    height: calc(100vh - 86px - 86px);
    overflow: auto;
    scrollbar-width: none;
  }
}

.character-desc__inner::-webkit-scrollbar {
  @include breakpoint('el') {
    display: none;
  }
}

.character-desc__inner--show-full {
  @include breakpoint('el') {
    height: initial;
  }
}

.character-desc__blockquote {
  margin: 0 0 70px;
  padding-left: 125px;
  background-image: url(./img/quotes.png);
  background-repeat: no-repeat;
  text-align: left;
}

.character-desc__blockquote p {
  margin: 0 0 15px;
}

.character-desc__blockquote .cite-author {
  font-family: var(--font-third);
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
}

.character-desc__text p {
  margin: 30px 0 0;
  text-align: left;
}

.character-desc__text p:first-child {
  margin: 0;
}

.character-desc__text {
  @include breakpoint('el') {
  }
}

.arrow-down {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 1px solid #4E4E4E;
  margin: 45px auto 0;
  cursor: pointer;
  transition: 0.3s;
  @include breakpoint('el') {
    width: 26px;
    height: 26px;
    border: none;
    position: absolute;
    display: none;
    bottom: 0;
    left: 50%;
    margin: 0 auto;
  }
}

.arrow-down--desktop {
  display: none;

  @include breakpoint('el') {
    display: flex;
  }
}

.arrow-down--open {

  transform: rotate(180deg);
}

.arrow-down svg {
  transform: rotate(180deg);
  @include breakpoint('el') {
    transform: none;
  }
}
