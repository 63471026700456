@import "./src/styles/breakpoints";

.person-list {
  min-height: 100vh;
  background-image: url(./img/bg_clouds.png), linear-gradient(180deg, #83C9F0 0%, #AEDFFB 100%);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  @include breakpoint('el') {
    background-size: auto auto;
  }

  .swiper-slide {
    height: 450px;
    width: 280px;
    padding-bottom: 10px;
    overflow: hidden;
    @include breakpoint('s') {
      height: 535px;
    }
    @include breakpoint('el') {
      // padding: 0 15px;
    }

  }

  .person-list__text, .person-list__img {
    @include breakpoint('el') {
      padding: 0 14px;
    }
  }

  .fake-button {
    position: absolute;
    @include breakpoint('el') {
      width: 144px;
      position: static;
    }
  }

  .person-list__navigation {
    width: 144px;
    display: flex;
    flex-direction: row-reverse;
    font-family: var(--font-third);
    font-size: 18px;
    font-weight: normal;
    color: #67B0D9;
    position: absolute;
    left: 50%;
    top: 57px;
    transform: translate(-50%, 0);
    @include breakpoint('el') {
      position: static;
      transform: none;
    }
  }

  .person-list__navigation button:focus {
    border: none;
    outline: none;
  }

  .person-list__prev,
  .person-list__next {
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
  }

  .person-list__fraction {
    padding: 0 10px;
    display: flex;
    justify-content: center;
  }

  .swiper-pagination-current, .swiper-pagination-total {
    width: calc(50% - 6px);
    text-align: center;
  }

  .swiper-container {
    width: 100%;
    padding-top: 25px;
    @include breakpoint('el') {
      padding-top: 0;
    }
  }
}

.person-list__bottom-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  @include breakpoint('m') {
    margin-top: 35px;
  }
  @include breakpoint('el') {
    justify-content: space-between;
  }

  @media (max-height: 900px) {
    margin-top: 5px;
  }
}

.person-list__row {
  min-height: calc(100vh - 60px);
  padding-bottom: 35px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  @include breakpoint('l') {
    min-height: calc(100vh - 86px);
    padding-bottom: 65px;
  }

  @include breakpoint('el') {
    justify-content: center;
  }
}

.person-list__swiper {
  overflow: initial;
}

.person-list__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-image: url(./img/swiper-overlay.png);
  background-repeat: no-repeat;
}


.person-list__test-link {
  text-decoration: none;
  width: 100%;
  max-width: 272px;
}

.person-item--order {
  order: -1;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(158, 158, 232, 0.2);

  .person-item {

  }

  .person-item__you {
    display: block;
  }
}

.person-item {
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  padding: 30px 10px 0;
  text-decoration: none;
  color: inherit;
  font-family: var(--font-four);
  text-align: center;
}

.person-item__you {
  display: none;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  color: #9E9EE8;
  font-weight: 400;
  font-family: var(--font-third);
  text-transform: uppercase;
}

.person-item__img img {
  margin: 0 auto;
  width: 250px;
  height: 250px;
  object-fit: contain;
}

.person-item__name {
  font-family: var(--font-third);
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: #4E4E4E;
}

.person-item__desc {
  font-size: 18px;
  font-weight: 300;
  color: var(--text-color-2);
  margin-top: 0;
  position: relative;
  overflow: hidden;
  height: 83px;
  @include breakpoint('m') {
    height: 148px;
  }

}
