.decorative-title {
  margin: 0;
  font-family: var(--font-primary);
  color: #4E4E4E;
  font-weight: 800;
  display: inline-block;
  text-align: center;
  background-position: 40% 9%, center;
  background-repeat: no-repeat;
  font-size: 40px;
  width: 100%;
  padding: 50px 0;
}

.decorative-title--registration {
  background-image: url(../images/chars/pink-fruit.png), url(../images/backgrounds/registration-title__bg.png);
  background-size: 50px 42px, 120% 100%;
}

.decorative-title--signIn {
  background-image: url(../images/chars/pink-fruit.png), url(../images/backgrounds/signin-title__bg.png);
  background-size: 50px 42px, 80% 100%;
}

.decorative-title--restorePass {
  background-image: url(../images/chars/pink-fruit.png), url(../images/backgrounds/restore-password__bg.png);
  background-size: 50px 42px, 120% 100%;
}

@media (min-width: 576px) {
  .decorative-title--registration {
    background-size: 50px 42px, 70% 100%;
  }

  .decorative-title--signIn {
    background-size: 50px 42px, 50% 100%;
  }

  .decorative-title--restorePass {
    background-size: 50px 42px, 100% 100%;
  }
}

@media (max-height: 850px) {
  .decorative-title {
    padding: 33px 0;
  }
}
