@import "./src/styles/breakpoints";

.plane {
  position: absolute;
  bottom: 410px;
  left: 0;
  height: 100px;
  animation: fly 23s infinite linear;
  animation-direction: reverse;

  @include breakpoint('el') {
    bottom: 0;
  }
}

.plane__img {
  position: relative;
  animation: float-plane 3s infinite linear;
  max-width: initial;
  height: 70px;
}


/*
@keyframes fly {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(200%);
  }
}
*/


@keyframes fly {
  0% {
    left: -840px;
  }
  95% {
    left: 100%;
  }

  100% {
    left: 200%;
  }
}


@keyframes float {
  0% {
    top: 5px;
  }
  50% {
    top: 15px;
  }
  100% {
    top: 5px;
  }
}

@keyframes float-plane {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(5px);
  }
}
