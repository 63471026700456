@import "./src/styles/breakpoints";

.cloud-woman {
  position: absolute;
  left: 0;
  top: -2%;
  width: 350px;
  z-index: var(--cloud-woman);
  display: none;

  @include breakpoint('el') {
    display: initial;
  }
}

.cloud-woman__clouds {
  position: relative;
  height: 160px;
  top: 30px;
}

.cloud-woman__text-wrapper {

  width: 100%;
}

.cloud-woman__text {
  right: 80px;
  font-size: 18px;
  font-family: var(--font-third);
  max-width: 100px;
  position: absolute;
  text-align: center;
  font-weight: 700;
  top: 57px;
  z-index: 10;
  opacity: 0;
}
