
.cloud-question__img {
  position: absolute;

  &--1 {
    bottom: 0;
    right: 193px;

  }

  &--2 {
    top: 120px;
    right: 170px;
  }

  &--3 {
    bottom: 0;
    right: 26px;
  }
}

.cloud-question__img-invert {
  position: absolute;

  &--1 {
    bottom: 0;
    left: 193px;

  }

  &--2 {
    top: 120px;
    left: 170px;
  }

  &--3 {
    bottom: 0;
    left: 26px;
  }
}

.cloud-question__text-anim {
  animation: showText 1s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}

@keyframes showText {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
