@import "./src/styles/breakpoints";

.top-clouds {

  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @include breakpoint('s') {
    display: flex;
  }

  @include breakpoint('el') {
    left: initial;
    transform: translateX(0);
    top: 20px;
    right: 10%;
    width: 50%;
  }
}

.top-clouds__bird {
  height: 5px;
  position: absolute;

}

.top-clouds__bird:nth-of-type(1) {
  top: 50px;
  left: 10px;
}
