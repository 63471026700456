.wrapper {
  max-width: var(--wrapper-width, 1920px);
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

#appleid-signin {
  width: 100%;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}
