@import "./src/styles/breakpoints";

.risk-man {
  display: none;
  @include breakpoint('el') {
    display: initial;
    position: absolute;
    right: -100px;
    top: 150px;
    animation: riskMan 12s infinite cubic-bezier(0.09, 0.62, 1, 1);
  }

}

.risk-man__img {
  animation: floatMan 2.5s infinite linear;
  position: relative;
}

@keyframes riskMan {
  0% {
    transform: translate(-50px, 100px) scale(0);
  }

  25% {
    transform: translate(500px, 300px) scale(0.85);
  }

  50% {
    transform: translate(-1000px, 100px) scale(0.5);
  }

  75% {
    transform: translate(-1500px, 300px) scale(1);
  }

  100% {
    transform: translate(-2000px, 200px) scale(0.4);
  }
}

@keyframes floatMan {
  0% {
    top: 5px;
  }
  50% {
    top: 25px;
  }
  100% {
    top: 5px;
  }
}
