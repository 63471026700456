@import "./src/styles/breakpoints";


.lighter-man {
  position: absolute;
  z-index: var(--lighter-man);
  top: 310px;

  display: none;
  left: 40px;

  @include breakpoint('el') {
    display: initial;
  }
}

.lighter-man img {
  animation: sway 6s infinite;
  // animation: sway 7s infinite;
  // animation-delay: 1s;
}

@keyframes sway {
  0% {
    transform: none;
    transform-origin: 5px 8px;
  }
  33% {
    transform: rotate(-5deg);
    transform-origin: 5px 8px;
  }
  66% {
    transform: none;
    transform-origin: 5px 8px;
  }
  100% {
    transform: none;
    transform-origin: 5px 8px;
  }
}

@keyframes sway1 {
  33% {
    transform: rotate(5deg);
    transform-origin: 5px 8px;
  }
  66% {
    transform: rotate(-5deg);
    transform-origin: 5px 8px;
  }
}

@keyframes sway2 {
  30% {
    transform: rotate(3deg);
    transform-origin: 5px 8px;
  }
  60% {
    transform: rotate(-3deg);
    transform-origin: 5px 8px;
  }
}
