.button {
  height: 50px;
  border: none;
  width: 100%;
  background-color: var(--contrast-color);
  color: var(--text-color);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-family: var(--font-third);
  cursor: pointer;
  border-radius: 3px;
  max-width: 465px;

  span {
    width: 175px;
  }
}

.icon {
  margin-right: 16px;
}
