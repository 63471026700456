.edit-input {
  position: relative;
}

.edit-input__control {
  width: 100%;
  max-width: 370px;
  height: 40px;
  border-radius: 3px;
  border: none;
  padding: 0 40px 0 20px;
  font-size: 20px;
  appearance: none;
  color: #828282;
  font-family: var(--font-third);
}

.edit-input__error {
  left: 0;
  bottom: -21px;
  position: absolute;
  font-size: 12px;
  color: var(--error-color);
}

.edit-input__control:disabled {
  background-color: #ffffff;
  opacity: 1;
}
