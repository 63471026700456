@import "./src/styles/breakpoints";

.inputWrapper {
  display: grid;
  position: relative;
  grid-gap: 1px;
  font-family: var(--font-third);
}

.label {
  font-size: 18px;
}

.input {
  border: 1px solid #E7F6FF;
  border-radius: 3px;
  font-size: 18px;
  padding: 10px 10px 10px 24px;
  max-width: 465px;
  width: 100%;
  appearance: none;
  line-height: 1;
  height: 50px;
  background-color: #E7F6FF;
  outline: none;
  transition: 0.3s;

  @include breakpoint('m') {
    padding: 10px 10px 10px 33px;
  }
}

.error {
  color: var(--error-color);
  font-size: 12px;
  position: absolute;
  bottom: -19px;
}

.inputError {
  border: 1px solid var(--error-color);
}

.input:focus:not(.inputError),
.input:hover:not(.inputError) {
  border: 1px solid var(--success-color);
}

.input::placeholder {
  color: var(--placeholder-color);
}
