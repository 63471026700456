@import "./src/styles/breakpoints";

.complete-test {
  font-size: 90px;
  font-family: var(--font-secondary);
  text-align: center;
  margin: 0 0 20px;

  @include breakpoint('l') {
    font-size: 100px;
  }
}
