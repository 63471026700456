@import "./src/styles/breakpoints";

.char-title {
  font-family: var(--font-third);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4E4E4E;
}

.char-title__text {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 10px;
  @include breakpoint('el') {
    font-size: 24px;
  }
}

.char-title__name  {
  font-size: 30px;
  text-transform: uppercase;
  @include breakpoint('el') {
    font-size: 40px;
  }
}
