@import "./src/styles/breakpoints";

.questions {
  position: relative;
  z-index: 100;
  background-image: url('./img/bg-question-mob.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  @include breakpoint('m') {
    background-image: url('./img/questions_bg_desc.jpg');
    background-size: cover;
    background-position: center center;
  }
}

.questions__progress-wrapper {
  background-color: #D3EEFD;
}

.questions__progress {
  height: 16px;
  background-color: #9E9EE8;
  transition: 0.3s;
}

.questions__body {
  padding: 15px 16px;
  // background-image: url('./img/bg-question-mob.png');
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: 100% 100%;
  min-height: calc(100vh - 16px);
  display: flex;
  flex-direction: column;

  @include breakpoint('m') {
    // background-image: url('./img/questions_bg_desc.jpg');
    // background-size: cover;
    // background-position: center center;
    // background-image: url('./img/bg-question-desc.jpg');
    justify-content: space-between;
    align-items: center;
  }

  @include breakpoint('el') {
    // background-size: cover;
    padding: 35px 10px 12vh;
  }
}

.questions__complete {
  display: flex;
  align-items: center;
  font-family: var(--font-four);
  cursor: pointer;

  span {
    font-size: 18px;
    font-weight: 300;
    margin-left: 5px;
  }
}

.questions__wrapper {
  display: grid;
  grid-gap: 25px;
  margin-bottom: 20px;
  max-width: 900px;
  width: 100%;

  @include breakpoint('el') {
    margin-bottom: 50px;
  }
}

.questions__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  @include breakpoint('el') {
    margin-bottom: 0;
    width: 90%;
  }
}

.questions__count {
  font-size: 18px;
  font-weight: 800;
  font-family: var(--font-third);
}

.questions__title {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-family: var(--font-third);
  margin:20px 0;
  height: 75px;

  @include breakpoint('s') {
    font-size: 20px;
  }

  @include breakpoint('m') {
    font-size: 22px;
    height: 110px;
    margin:0 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.questions__radiobox {
  min-height: 78px;
  background-color: #fff;
  font-size: 20px;
  font-weight: 300;
  border-radius: 10px;
  font-family: var(--font-four);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 10px;
  text-align: center;
  cursor: pointer;
  position: relative;

  @include breakpoint('el') {
    font-size: 26px;
    padding: 39px 10px;
  }
}

.questions__bg-cloud {
  position: absolute;
  opacity: 0;
  transition: 0.3s;
  width: 120%;
  height: 114px;
  margin-left: -10%;
  z-index: -1;
  bottom: -50px;
  background-image: url('./img/checked-question.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;

  @include breakpoint('s') {
    height: 174px;
    bottom: -80px;
  }

  @include breakpoint('m') {
    height: 204px;
    bottom: -100px;
  }

  @include breakpoint('el') {
    width: 130%;
    height: 220px;
    margin-left: -15%;
  }
}

.questions__nav-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 673px;
  width: 100%;
  margin-top: auto;
  margin-bottom: 120px;

  @include breakpoint('l') {
    margin-top: initial;
    margin-bottom: initial;
  }
}

.questions__item {
  max-width: 673px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.questions__item-input:checked ~ .questions__radiobox {
  background: #7B7BCD;
  color: #ffffff;
  position: relative;
}

.questions__item-input:checked ~ .questions__bg-cloud {
  opacity: 1;
  transition-delay: 0.3s;
}

.questions__prev {
  cursor: pointer;
  color: #ffffff;
  font-size: 30px;
  font-family: var(--font-secondary);
  border: none;
  padding: 5px 10px;
  background-color: transparent;
  background-image: url(./img/back-underline.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
}

.questions__fake-block {
  display: none;

  @include breakpoint('el') {
    display: block;
    margin-right: 5%;
  }
}

.questions__prev:disabled {
  opacity: 0.5;
}

.questions__next {
  cursor: pointer;
  border: none;
  color: #ffffff;
  background-color: #B1CA1D;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 237px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 56px;
  font-size: 30px;
  font-family: var(--font-secondary);
  text-decoration: none;
}

.questions__next:disabled,
.questions__next--disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
