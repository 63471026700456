@import "./src/styles/breakpoints";

.text-clouds {
  position: absolute;
  bottom: 5%;
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 50%;
  transform: translateX(-50%);
  height: 280px;
  /*animation: cloudsQuestion 35s infinite ease-in-out;*/

  @include breakpoint('m') {
    flex-wrap: nowrap;
    bottom: 0;
  }

  @include breakpoint('el') {
    bottom: initial;
    top: 2%;
    left: 30%;
    display: none;
  }

  @include breakpoint('xxl') {
    animation: cloudsQuestionDesk 15s infinite ease-in-out;
    animation-direction: alternate;
  }

  img {
    margin: 0 auto;
  }
}

.text-clouds__1 {
  width: 80%;
  display: flex;
  justify-content: center;
  margin-left: 20%;

  @include breakpoint('m') {
    margin-left: 0;
    width: auto;
    position: relative;
    flex-grow: 1;
    transform: scale(1.4);
  }

  .text-clouds__cloud {
    width: 60%;
    max-width: 200px;
  }
}

.text-clouds__2 {
  width: 45%;
  position: relative;
  margin-top: -80px;
  margin-left: -20px;

  @include breakpoint('m') {
    width: auto;
    transform: scale(1.4);
    flex-grow: 1;
  }

  .text-clouds__cloud {
    max-width: 200px;
  }
}

.text-clouds__3 {
  width: 54%;
  position: relative;

  @include breakpoint('m') {
    flex-grow: 1;
    width: auto;
    transform: scale(1.4);
  }

  .text-clouds__cloud {
    max-width: 220px;
  }
}

.text-clouds__text-wrapper {
  display: block;
  width: 100%;
  position: relative;
}

.text-clouds__text {
  font-family: var(--font-third);
  max-width: 110px;
  position: absolute;
  text-align: center;
  font-size: 17px;
  font-weight: 700;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-clouds__sun {
  max-width: 100px;
  position: absolute;
  width: 50%;
  left: calc(50% - 40px);
  top: -52px;
}

.text-clouds__phil {
  position: absolute;
  left: calc(60% - 65px);
  top: -70px;
  max-width: 130px;
}

.text-clouds__risk {
  width: 100%;
  position: absolute;
  left: calc(30% + 36px);
  top: -57px;
  max-width: 130px;

  @include breakpoint('m') {
    left: calc(20% + 36px);
    top: -67px;
  }
}

.text-clouds__bird1 {
  position: absolute;
  left: calc(40% - 40px);
  top: 40px;
}

.text-clouds__bird2 {
  position: absolute;
  left: calc(60% + 34px);
  top: 9px;
}

.text-clouds__bird3 {
  position: absolute;
  left: calc(60% + 15px);
  top: -12px;
}

@keyframes cloudsQuestion {
  0% {
    transform: translateX(-50%);
  }


  50% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(-50%);
  }
}

@keyframes cloudsQuestionDesk {
  0% {
    transform: translateX(-50%) scale(1.5);
  }


  50% {
    transform: translateX(50%) scale(1);
  }

  100% {
    transform: translateX(100%) scale(1.5);
  }
}
